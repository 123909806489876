import {
  useTonAddress,
  useTonConnectModal,
  useTonConnectUI,
} from "@tonconnect/ui-react";
import { beginCell, Address, Cell } from "@ton/ton";

export function useTonWalletKit() {
  const _address = useTonAddress(true);
  const [tonConnectUI] = useTonConnectUI();

  const { open } = useTonConnectModal();

  const onDisconnect = async () => {
    await tonConnectUI.disconnect();
  };

  const transfer = async (params: any) => {
    // https://github.com/ton-org/ton/issues/44
    const {
      depositAddr,
      fee,
      amount,
      jettonWalletAddr,
      sender,
      receiver,
      targetChainId,
    } = params;
    const burnFee = 50000000n;
    // https://docs.rs/tonlib-core/latest/tonlib_core/message/constant.JETTON_BURN.html
    const JETTON_BURN = 0x595f07bc;

    const senderAddr = Address.parse(sender);
    const jettonAddr = Address.parseRaw(jettonWalletAddr);
    const customPayload = beginCell()
      .storeStringTail(
        JSON.stringify({
          target_chain: targetChainId,
          receiver,
        }),
      )
      .endCell();

    const timestamp = Math.floor(Date.now() / 1000);
    const burnBody = beginCell()
      .storeUint(JETTON_BURN, 32)
      .storeUint(timestamp, 64)
      .storeCoins(amount)
      .storeAddress(senderAddr)
      .storeUint(1, 1)
      .storeRef(customPayload)
      .endCell();

    const { boc } = await tonConnectUI.sendTransaction({
      validUntil: timestamp + 360,
      messages: [
        {
          address: jettonAddr.toString(),
          amount: burnFee.toString(),
          payload: burnBody.toBoc().toString("base64"),
        },
        {
          address: depositAddr,
          amount: fee.toString(),
        },
      ],
    });

    const messageHash = Cell.fromBase64(boc).hash().toString("base64");

    return messageHash;
  };

  return {
    address: _address,
    onShowModal: open,
    transfer,
    onDisconnect,
  };
}
