import { useTransferContext } from "../../context/TransferContext";
import BaseSubmit from "./BaseSubmit";
import { useAddRunesContext } from "../../context/AddRunesContext";

export function SubmitAddRunes() {
  const { submitRequest } = useAddRunesContext();

  return <BaseSubmit submitRequest={submitRequest} />;
}

export function SubmitTransfer() {
  const { submitRequest } = useTransferContext();

  return <BaseSubmit submitRequest={submitRequest} />;
}
