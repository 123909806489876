import { createActorFunctionType } from "@types";

const PREFIX = "IWK";

export enum EventType {
  ON_CONNECT = PREFIX + "ON_CONNECT",
  ON_DISCONNECT = PREFIX + "ON_DISCONNECT",
  ON_TRANSFER = PREFIX + "ON_TRANSFER",
  ON_ERROR = PREFIX + "ON_ERROR",
  ON_BALANCE_CHANGE = PREFIX + "ON_BALANCE_CHANGE",
}

export enum WalletType {
  Plug = "Plug",
  Bitfinity = "Bitfinity",
}

export interface ICPWalletKitProviderProps {
  address: string;
  connector?: WalletType;
  onShowModal: (params: { whitelist: string[] }) => void;
  onHideModal: () => void;
  onDisconnect: () => void;
  createActor: createActorFunctionType;
  transfer: (params: {
    to: string;
    amount: bigint;
  }) => Promise<bigint | number | undefined>;
}
