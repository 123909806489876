import {
  Badge,
  HStack,
  Link,
  Text,
  useColorModeValue,
  VStack,
} from "@chakra-ui/react";
import { useHubContext } from "@context/OmnityHubContext";
import BitcoinCustomsService from "@services/BitcoinCustomsService";
import BitcoinService from "@services/BitcoinService";
import { SendEtchingInfo } from "@services/candids/BitcoinCustoms.did";
import ServiceFactory from "@services/Factory";
import { ChainID, Etching } from "@types";
import { BADGE_COLORS } from "@utils/constants";
import dayjs from "dayjs";
import { useEffect, useState } from "react";

function formatEtchingTime(_time: number) {
  if (_time < 0) {
    return "-";
  }
  const _now = Date.now();
  if (_now - _time > 1000 * 60 * 60 * 24 * 15) {
    return dayjs(_time).format("MM-DD HH:mm:ss");
  }
  return dayjs(_time).fromNow();
}

export default function EtchingItemComp({ etching }: { etching: Etching }) {
  const [etchingInfo, setEtchingInfo] = useState<SendEtchingInfo>();
  const { chains } = useHubContext();

  useEffect(() => {
    const chain = chains.find((c) => c.chain_id === ChainID.Bitcoin);
    if (chain) {
      const service = ServiceFactory.createService(
        chain,
      ) as BitcoinCustomsService;
      service.getEtchings([etching.id]).then((info) => {
        setEtchingInfo(info[0]);
      });
    }
  }, [etching.id]);

  const boxWrapperHoverBg = useColorModeValue("gray.100", "gray.800");

  const status = etchingInfo?.status ? Object.keys(etchingInfo.status)[0] : "";

  return (
    <VStack
      w="100%"
      alignItems="flex-start"
      py={2}
      px={6}
      _hover={{
        bg: boxWrapperHoverBg,
      }}
      borderBottomWidth={0.5}
      borderBottomColor={boxWrapperHoverBg}
      gap={1}
      pos="relative"
    >
      <HStack w="100%" justifyContent="space-between">
        <Text color="gray.500" fontSize={14}>
          {formatEtchingTime(etching.timestamp)}
        </Text>
        <HStack>
          <Badge colorScheme={BADGE_COLORS["Etch"]}>Etching</Badge>
        </HStack>
      </HStack>
      <HStack w="100%" justifyContent="space-between">
        <Text color="gray.400">Commit Tx ID</Text>
        <Link href={`https://mempool.space/tx/${etching.id}`}>
          <Text maxW={200} isTruncated color="blue.400" fontFamily="monospace">
            {etching.id}
          </Text>
        </Link>
      </HStack>
      <HStack w="100%" justifyContent="space-between">
        <Text color="gray.400">Reveal Tx ID</Text>
        <Link href={`https://mempool.space/tx/${etchingInfo?.reveal_txid}`}>
          <Text maxW={200} isTruncated color="blue.400" fontFamily="monospace">
            {etchingInfo?.reveal_txid}
          </Text>
        </Link>
      </HStack>
      <HStack w="100%" justifyContent="space-between">
        <Text color="gray.400">Runes</Text>
        <HStack>
          <Text fontWeight={600}>{etching.name}</Text>
          <Text>{etching.symbol}</Text>
        </HStack>
      </HStack>
      <HStack w="100%" justifyContent="space-between">
        <Text color="gray.400">Status</Text>
        <Text>{status}</Text>
      </HStack>
    </VStack>
  );
}
