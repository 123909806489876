import { AssetType, ChainID } from "@types";

const PREFIX = "OWK";

export enum EventType {
  ON_CONNECT = PREFIX + "ON_CONNECT",
  ON_DISCONNECT = PREFIX + "ON_DISCONNECT",
  ON_TRANSFER = PREFIX + "ON_TRANSFER",
  ON_ERROR = PREFIX + "ON_ERROR",
  ON_BALANCE_CHANGE = PREFIX + "ON_BALANCE_CHANGE",
}

export enum WalletType {
  Keplr = "Keplr",
}

export interface TransferParams {
  amount: bigint;
  fromAddr: string;
  toAddr: string;
  assetType: AssetType;
  targetChainId: ChainID;
  tokenId: string;
  denom: string;
  contractAddr: string;
}

export interface OsmosisWalletKitProviderProps {
  address: string;
  connector?: WalletType;
  balance?: bigint;
  onShowModal: () => void;
  onHideModal: () => void;
  onDisconnect: () => void;
  transfer: (params: TransferParams) => Promise<string>;
}
