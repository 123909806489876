import { Etching } from "@types";
import { useAtom } from "jotai";
import { atomWithStorage } from "jotai/utils";

const etchingsAtom = atomWithStorage<Etching[]>("omnity.etchings", []);

export default function useEtchings() {
  const [etchings, setEtchings] = useAtom(etchingsAtom);

  return {
    etchings,
    addEtching: (s: Etching) => setEtchings((prev) => [s, ...prev]),
  };
}
