export const idlFactory = ({ IDL }) => {
  const InitArgs = IDL.Record({
    hub_principal: IDL.Principal,
    cw_rpc_url: IDL.Text,
    cw_rest_url: IDL.Text,
    chain_id: IDL.Text,
    cosmwasm_port_contract_address: IDL.Text,
  });
  const Result = IDL.Variant({ Ok: IDL.Nat64, Err: IDL.Text });
  const RouteError = IDL.Variant({
    SendTicketErr: IDL.Text,
    AttributeNotFound: IDL.Tuple(IDL.Text, IDL.Text),
    HttpOutCallError: IDL.Tuple(IDL.Text, IDL.Text, IDL.Text),
    HttpStatusError: IDL.Tuple(IDL.Nat, IDL.Text, IDL.Text),
    ConfirmMintTokenErr: IDL.Tuple(IDL.Text, IDL.Text),
    QueryEcdsaPublicKeyError: IDL.Tuple(IDL.Text, IDL.Text),
    AttributeParseError: IDL.Tuple(IDL.Text, IDL.Text, IDL.Text),
    RouteRpcResponseMismatch: IDL.Tuple(IDL.Text, IDL.Text),
    ConfirmExecuteDirectiveErr: IDL.Tuple(IDL.Nat64, IDL.Text),
    TxAlreadyHandled: IDL.Text,
    EventNotFound: IDL.Text,
    CallError: IDL.Tuple(IDL.Text, IDL.Principal, IDL.Text, IDL.Text),
    MintTokenTicketAlreadyExist: IDL.Tuple(IDL.Text, IDL.Text),
    CustomError: IDL.Text,
    SignWithEcdsaError: IDL.Tuple(IDL.Text, IDL.Text),
  });
  const Result_1 = IDL.Variant({ Ok: IDL.Text, Err: RouteError });
  const ChainState = IDL.Variant({
    Active: IDL.Null,
    Deactive: IDL.Null,
  });
  const ChainType = IDL.Variant({
    SettlementChain: IDL.Null,
    ExecutionChain: IDL.Null,
  });
  const Chain = IDL.Record({
    fee_token: IDL.Opt(IDL.Text),
    canister_id: IDL.Text,
    chain_id: IDL.Text,
    counterparties: IDL.Opt(IDL.Vec(IDL.Text)),
    chain_state: ChainState,
    chain_type: ChainType,
    contract_address: IDL.Opt(IDL.Text),
  });
  const TokenResp = IDL.Record({
    decimals: IDL.Nat8,
    token_id: IDL.Text,
    token_denom: IDL.Opt(IDL.Text),
    icon: IDL.Opt(IDL.Text),
    rune_id: IDL.Opt(IDL.Text),
    symbol: IDL.Text,
  });
  const MintTokenStatus = IDL.Variant({
    Finalized: IDL.Record({ tx_hash: IDL.Text }),
    Unknown: IDL.Null,
  });
  const Result_2 = IDL.Variant({ Ok: IDL.Text, Err: IDL.Text });
  const IcpChainKeyToken_1 = IDL.Variant({ CKBTC: IDL.Null });
  const TxAction_1 = IDL.Variant({
    Burn: IDL.Null,
    Redeem: IDL.Null,
    Mint: IDL.Null,
    RedeemIcpChainKeyAssets: IcpChainKeyToken_1,
    Transfer: IDL.Null,
  });
  const GenerateTicketReq = IDL.Record({
    seq: IDL.Nat64,
    fee_token: IDL.Text,
    action: TxAction_1,
    token_id: IDL.Text,
    memo: IDL.Opt(IDL.Text),
    sender: IDL.Text,
    target_chain_id: IDL.Text,
    timestamp: IDL.Nat64,
    fee_amount: IDL.Text,
    amount: IDL.Text,
    block_height: IDL.Nat64,
    receiver: IDL.Text,
  });
  const Result_3 = IDL.Variant({
    Ok: IDL.Vec(IDL.Text),
    Err: RouteError,
  });
  const Token = IDL.Record({
    decimals: IDL.Nat8,
    token_id: IDL.Text,
    metadata: IDL.Vec(IDL.Tuple(IDL.Text, IDL.Text)),
    icon: IDL.Opt(IDL.Text),
    name: IDL.Text,
    symbol: IDL.Text,
  });
  const MultiRpcConfig = IDL.Record({
    rpc_list: IDL.Vec(IDL.Text),
    minimum_response_count: IDL.Nat32,
  });
  const FeeTokenFactor = IDL.Record({
    fee_token: IDL.Text,
    fee_token_factor: IDL.Nat,
  });
  const TargetChainFactor = IDL.Record({
    target_chain_id: IDL.Text,
    target_chain_factor: IDL.Nat,
  });
  const Factor = IDL.Variant({
    UpdateFeeTokenFactor: FeeTokenFactor,
    UpdateTargetChainFactor: TargetChainFactor,
  });
  const ToggleAction = IDL.Variant({
    Deactivate: IDL.Null,
    Activate: IDL.Null,
  });
  const ToggleState = IDL.Record({
    action: ToggleAction,
    chain_id: IDL.Text,
  });
  const Directive = IDL.Variant({
    UpdateChain: Chain,
    UpdateFee: Factor,
    AddToken: Token,
    AddChain: Chain,
    ToggleChainState: ToggleState,
    UpdateToken: Token,
  });
  const TicketType = IDL.Variant({
    Resubmit: IDL.Null,
    Normal: IDL.Null,
  });
  const Ticket = IDL.Record({
    token: IDL.Text,
    action: TxAction_1,
    dst_chain: IDL.Text,
    memo: IDL.Opt(IDL.Vec(IDL.Nat8)),
    ticket_id: IDL.Text,
    sender: IDL.Opt(IDL.Text),
    ticket_time: IDL.Nat64,
    ticket_type: TicketType,
    src_chain: IDL.Text,
    amount: IDL.Text,
    receiver: IDL.Text,
  });
  const RouteState = IDL.Record({
    hub_principal: IDL.Principal,
    unverified_generate_ticket_requests: IDL.Vec(GenerateTicketReq),
    cw_rpc_url: IDL.Text,
    token_denom: IDL.Vec(IDL.Tuple(IDL.Text, IDL.Text)),
    cw_chain_key_derivation_path: IDL.Vec(IDL.Vec(IDL.Nat8)),
    highest_validator_set_data: IDL.Vec(IDL.Nat8),
    next_directive_seq: IDL.Nat64,
    fee_token_id: IDL.Text,
    cw_rest_url: IDL.Text,
    cw_public_key_vec: IDL.Opt(IDL.Vec(IDL.Nat8)),
    trust_state: IDL.Vec(IDL.Nat8),
    chain_id: IDL.Text,
    tokens: IDL.Vec(IDL.Tuple(IDL.Text, Token)),
    cw_port_contract_address: IDL.Text,
    target_chain_factor: IDL.Vec(IDL.Tuple(IDL.Text, IDL.Nat)),
    multi_rpc_config: MultiRpcConfig,
    counterparties: IDL.Vec(IDL.Tuple(IDL.Text, Chain)),
    next_ticket_seq: IDL.Nat64,
    chain_state: ChainState,
    processing_directive: IDL.Vec(IDL.Tuple(IDL.Nat64, Directive)),
    failed_tickets: IDL.Vec(IDL.Tuple(IDL.Nat64, Ticket)),
    fee_token_factor: IDL.Opt(IDL.Nat),
  });
  const UpdateCwSettingsArgs = IDL.Record({
    cw_rpc_url: IDL.Opt(IDL.Text),
    cw_rest_url: IDL.Opt(IDL.Text),
    cw_port_contract_address: IDL.Opt(IDL.Text),
    multi_rpc_config: IDL.Opt(MultiRpcConfig),
  });
  return IDL.Service({
    cache_public_key: IDL.Func([], [], []),
    fetch_block_as_trusted_state: IDL.Func([IDL.Nat64], [Result], []),
    generate_ticket: IDL.Func([IDL.Text], [Result_1], []),
    get_chain_list: IDL.Func([], [IDL.Vec(Chain)], ["query"]),
    get_fee: IDL.Func([IDL.Text], [IDL.Opt(IDL.Nat64)], ["query"]),
    get_token_list: IDL.Func([], [IDL.Vec(TokenResp)], ["query"]),
    mint_token_status: IDL.Func([IDL.Text], [MintTokenStatus], ["query"]),
    osmosis_account_id: IDL.Func([], [Result_2], []),
    query_redeemed_tickets: IDL.Func(
      [],
      [IDL.Vec(IDL.Tuple(IDL.Text, IDL.Text))],
      ["query"],
    ),
    query_verified_generate_ticket_requests: IDL.Func(
      [],
      [IDL.Vec(GenerateTicketReq)],
      ["query"],
    ),
    redeem: IDL.Func([IDL.Text], [Result_1], []),
    retry_failed_tickets: IDL.Func([IDL.Opt(IDL.Text)], [Result_3], []),
    retry_light_client_verify: IDL.Func([], [], []),
    route_state: IDL.Func(
      [],
      [RouteState, IDL.Text, IDL.Vec(IDL.Text)],
      ["query"],
    ),
    start_light_client_verify_new_block: IDL.Func([], [], []),
    start_process_directive: IDL.Func([], [], []),
    start_process_ticket: IDL.Func([], [], []),
    update_cw_settings: IDL.Func([UpdateCwSettingsArgs], [], []),
  });
};
export const init = ({ IDL }) => {
  const InitArgs = IDL.Record({
    hub_principal: IDL.Principal,
    cw_rpc_url: IDL.Text,
    cw_rest_url: IDL.Text,
    chain_id: IDL.Text,
    cosmwasm_port_contract_address: IDL.Text,
  });
  return [InitArgs];
};
