import {
  HStack,
  Input,
  InputGroup,
  InputRightAddon,
  Radio,
  Text,
  VStack,
} from "@chakra-ui/react";
import RunesSelector from "@components/RunesSelector";
import { useAddRunesContext } from "@context/AddRunesContext";
import { useHubContext } from "@context/OmnityHubContext";
import { ChainName, ServiceType, SubmitRequire } from "@types";
import ChainLogo from "@components/common/ChainLogo";
import ItemSelected from "@components/common/ItemSelected";
import { useMemo } from "react";
import { formatUnits } from "@utils/format";
import { ADD_RUNES_SUFFIX, ICP_DECIMALS } from "@utils/constants";
import CurrentWallet from "@components/common/CurrentWallet";
import ChainButton from "@components/common/ChainButton";
import { OminityCard } from "./OminityCard";
import { SubmitAddRunes } from "@components/SubmitButton/SubmitButton";

export default function BitfinityAddRunes({ width }: { width?: number }) {
  return (
    <OminityCard
      width={width}
      cardTitle="Add Runes"
      cardComponent={<AddRunesComponent />}
      submissionComponent={<SubmitAddRunes />}
    />
  );
}

const AddRunesComponent = () => {
  const {
    selectedRune,
    selectedChain,
    onSelectChain,
    token,
    symbol,
    onSymbolChange,
    serviceFee,
    submitRequest,
  } = useAddRunesContext();
  const { chains } = useHubContext();

  const _chains = useMemo(() => {
    const chainsAdded = chains
      .filter((chain) =>
        chain.token_list?.some((token) => token.token_id === token.token_id),
      )
      .sort((a, b) => a.chain_name.localeCompare(b.chain_name));
    const chainsNotAdded = chains
      .filter(
        (chain) =>
          !chain.token_list?.some((token) => token.token_id === token.token_id),
      )
      .sort((a, b) => a.chain_name.localeCompare(b.chain_name));

    return [...chainsAdded, ...chainsNotAdded].filter((chain) => {
      if (!selectedRune) {
        return false;
      }
      return true;
    });
  }, [token?.token_id, chains.length, selectedRune?.rune_id]);

  return (
    <>
      <VStack w="100%" alignItems="flex-start" gap={1}>
        <Text textStyle="h6" color="text.secondary" fontWeight={600}>
          Runes
        </Text>
        <RunesSelector />
      </VStack>

      <VStack w="100%" alignItems="flex-start" gap={1}>
        <Text fontWeight={600} textStyle="h6" color="text.secondary">
          Select Chains
        </Text>

        <VStack
          w="100%"
          gap={0}
          bg="bg.module"
          borderRadius={4}
          maxH={300}
          overflowY="scroll"
        >
          {!selectedRune && (
            <HStack p={2} py={3} w="100%" justifyContent="flex-start">
              <Text fontSize={18}>Please select runes first</Text>
            </HStack>
          )}
          {_chains
            .filter((c) => c.service_type === ServiceType.Route)
            .map((chain, idx) => {
              const added = chain.token_list?.some(
                (t) => t.token_id === token?.token_id,
              );

              const isLast = idx === _chains.length - 1;
              return (
                <HStack
                  key={chain.canister_id}
                  w="100%"
                  py={2}
                  px={4}
                  borderBottomWidth={isLast ? 0 : 0.5}
                  borderBottomColor="border.secondary"
                  justifyContent="space-between"
                  cursor={"pointer"}
                  _hover={{
                    bg: "bg.darkHover",
                  }}
                  onClick={() => !added && onSelectChain?.(chain.chain_name)}
                >
                  <HStack>
                    <ChainLogo chain={chain.chain_id} size={36} />
                    <Text fontSize={20}>{chain.chain_name}</Text>
                  </HStack>

                  {added ? (
                    <ItemSelected size={22} />
                  ) : (
                    <Radio
                      borderColor="blue.300"
                      size="lg"
                      isChecked={selectedChain === chain.chain_name}
                    />
                  )}
                </HStack>
              );
            })}
        </VStack>
      </VStack>

      <VStack w="100%" gap={2} alignItems="flex-start">
        <Text fontWeight={600} textStyle="h6" color="text.secondary">
          Symbol
        </Text>
        <InputGroup size="lg" bg="bg.darkMain" borderRadius={4}>
          <Input
            color="text.secondary"
            bg="bg.darkMain"
            py={4}
            px={2}
            lineHeight={1}
            borderWidth={0}
            height="100%"
            fontSize={16}
            outline="none"
            placeholder="Runes Symbol"
            value={symbol}
            disabled={!!token}
            onChange={(e) => {
              const value = e.target.value.trim().toUpperCase();
              if (/^[A-Z]*$/.test(value)) {
                onSymbolChange?.(value);
              }
            }}
            _hover={{
              bg: "bg.darkHover",
            }}
            _focus={{
              borderWidth: 0,
              outline: "none",
              boxShadow: "none",
            }}
          />
          {!token && (
            <InputRightAddon
              fontSize={16}
              lineHeight={1}
              height="100%"
              bg="bg.darkMain"
              py={4}
              borderWidth={0}
            >
              {ADD_RUNES_SUFFIX}
            </InputRightAddon>
          )}
        </InputGroup>
        {submitRequest === SubmitRequire.Invalid_Symbol && (
          <Text fontSize={14} color="red.400">
            {"The symbol has been taken"}
          </Text>
        )}
      </VStack>

      <VStack w="100%" alignItems="flex-start" gap={1}>
        <Text fontWeight={600} textStyle="h6" color="text.secondary">
          Fee
        </Text>
        <VStack
          w="100%"
          alignItems="flex-start"
          bg="bg.darkMain"
          p={2}
          borderRadius={4}
          fontSize={18}
        >
          {selectedRune ? (
            <Text>
              {formatUnits(
                token ? serviceFee?.add_chain_fee : serviceFee?.add_token_fee,
                ICP_DECIMALS,
              )}{" "}
              ICP
            </Text>
          ) : (
            <Text>-</Text>
          )}
        </VStack>
      </VStack>

      <VStack w={{ base: "100%" }} flex={1} alignItems="flex-start" gap={1}>
        <HStack w="100%" gap={2} justifyContent="space-between">
          <Text fontWeight={600}>Wallet</Text>
          <CurrentWallet chain={ChainName.ICP} isSource />
        </HStack>
        <ChainButton chainName={ChainName.ICP} isSource changable={false} />
      </VStack>
    </>
  );
};
