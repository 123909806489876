import {
  chakra,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Link,
  Switch,
  Text,
  useColorModeValue,
  VStack,
} from "@chakra-ui/react";
import ChainButton from "@components/common/ChainButton";
import CurrentWallet from "@components/common/CurrentWallet";
import MintTokenSelector from "@components/MintTokenSelector";
import { formatUnits, readableNumber } from "@utils/format";
import { ChainSubmitButton } from "@components/SubmitButton/BaseSubmit";
import { useEffect, useState } from "react";
import { Token } from "@types";
import TokenOnTargetChain from "@components/TokenOnTargetChain";
import { ArrowUpRight } from "lucide-react";
import { SubmitTransfer } from "@components/SubmitButton/SubmitButton";
import useServices from "@hooks/useServices";
import { useTransferContext } from "@context/TransferContext";

const ArrowUpRightIcon = chakra(ArrowUpRight);

export default function Mint({ width }: { width?: number }) {
  const {
    sourceChain,
    token,
    amount,
    useConnectWalletForReceiver,
    targetAddr,
    onToggleConnectWalletForReceiver,
    onTargetAddrChange,
  } = useTransferContext();

  const [targetToken, setTargetToken] = useState<Token>();
  const [loadingTargetToken, setLoadingTargetToken] = useState(false);
  const { sourceService } = useServices();

  useEffect(() => {
    if (sourceService && token) {
      setLoadingTargetToken(true);
      sourceService.fetchTokens([token.token_id], undefined).then((tokens) => {
        setLoadingTargetToken(false);
        setTargetToken(tokens[0]);
      });
    }
  }, [sourceService?.chain.chain_id, token?.token_id, targetToken?.token_id]);

  const boxWrapperBg = useColorModeValue("#eee", "gray.700");
  const inputBoxWrapperBg = useColorModeValue("white", "gray.800");
  const textColor = useColorModeValue("#333", "#eee");
  const inputTextColor = useColorModeValue("gray.800", "gray.200");

  return (
    <VStack w={{ base: "100%", md: width }} gap={6}>
      <VStack
        width="99%"
        p={{ base: 4, md: 6 }}
        borderRadius={8}
        gap={4}
        bg={inputBoxWrapperBg}
        color={textColor}
      >
        <VStack w="100%" alignItems="flex-start" gap={1}>
          <HStack w="100%" justifyContent="space-between">
            <Text fontWeight={600}>L2 Chain</Text>
            <CurrentWallet chain={sourceChain} />
          </HStack>
          <ChainButton chainName={sourceChain} />
        </VStack>

        <VStack w="100%" alignItems="flex-start" gap={1}>
          <HStack w="100%" justifyContent="space-between">
            <Text fontWeight={600}>Runes</Text>
            {token && (
              <Link
                href={`https://www.runescan.net/runes/${token.name}`}
                target="_blank"
                color="blue.400"
                fontSize={12}
              >
                <HStack gap={0}>
                  <Text>View on Runescan</Text>
                  <ArrowUpRightIcon size={16} strokeWidth={1} />
                </HStack>
              </Link>
            )}
          </HStack>
          <MintTokenSelector />
        </VStack>

        <VStack w="100%" alignItems="flex-start" gap={1}>
          <Text fontWeight={600}>Amount</Text>
          <VStack
            w="100%"
            alignItems="flex-start"
            bg={boxWrapperBg}
            p={2}
            borderRadius={4}
          >
            {token ? (
              <Text>
                {readableNumber(formatUnits(BigInt(amount), token.decimals))}{" "}
                {token.symbol}
              </Text>
            ) : (
              <Text>-</Text>
            )}
          </VStack>
        </VStack>

        <VStack w="100%" alignItems="flex-start" gap={1}>
          <HStack w="100%" justifyContent="space-between">
            <Text fontWeight={600}>Receiver</Text>
            <HStack>
              <FormControl display="flex" alignItems="center">
                <FormLabel htmlFor="connected-target-addr" mb="0">
                  use connected wallet
                </FormLabel>
                <Switch
                  id="connected-target-addr"
                  isChecked={useConnectWalletForReceiver}
                  onChange={onToggleConnectWalletForReceiver}
                />
              </FormControl>
            </HStack>
          </HStack>
          <HStack w="100%" borderRadius={4}>
            {((useConnectWalletForReceiver && targetAddr) ||
              !useConnectWalletForReceiver) && (
              <Input
                color={inputTextColor}
                bg={boxWrapperBg}
                py={4}
                px={2}
                lineHeight={1}
                borderWidth={0}
                fontFamily="monospace"
                height="100%"
                outline="none"
                placeholder={`${sourceChain} Address`}
                value={targetAddr}
                onChange={(e) =>
                  !useConnectWalletForReceiver &&
                  onTargetAddrChange(e.target.value)
                }
                _focus={{
                  borderWidth: 0,
                  outline: "none",
                  boxShadow: "none",
                }}
              />
            )}
            {useConnectWalletForReceiver && !targetAddr && sourceChain && (
              <ChainSubmitButton chainName={sourceChain} forSubmit={false} />
            )}
          </HStack>
        </VStack>

        {token && (
          <TokenOnTargetChain
            chainName={sourceChain}
            token={targetToken}
            isLoading={loadingTargetToken}
            service={sourceService}
          />
        )}
      </VStack>

      <SubmitTransfer />
    </VStack>
  );
}
