import {
  Token,
  BridgeStep,
  OnBridgeParams,
  OnBurnParams,
  Ticket,
  TicketStatusResult,
  ChainID,
  BridgeFee,
  TxStatus,
  GenerateTicketResult,
} from "@types";
import BaseService from "./BaseService";
import { validate, Network } from "bitcoin-address-validation";

const bitcoinIndexerApi = "https://mempool.space/api";

export default class BitcoinService extends BaseService {
  getBridgeSteps(token?: Token): BridgeStep[] {
    throw new Error("Method not implemented.");
  }
  onBridge(params: OnBridgeParams): Promise<string> {
    throw new Error("Method not implemented.");
  }
  onBurn(params: OnBurnParams): Promise<string> {
    throw new Error("Method not implemented.");
  }
  onMint(params: OnBridgeParams): Promise<string> {
    throw new Error("Method not implemented.");
  }
  generateTicket(ticket: Ticket): Promise<GenerateTicketResult> {
    throw new Error("Method not implemented.");
  }
  getTicketStatus(ticket_id: string): Promise<TicketStatusResult> {
    throw new Error("Method not implemented.");
  }
  getBridgeFee(targetChainId: ChainID, token?: Token): Promise<BridgeFee> {
    throw new Error("Method not implemented.");
  }

  static async fetchFeeRate() {
    try {
      const gasFee = await fetch(
        `${bitcoinIndexerApi}/v1/fees/recommended`,
      ).then((res) => res.json());
      return {
        fastestFee: gasFee.fastestFee,
        halfHourFee: gasFee.halfHourFee,
        hourFee: gasFee.hourFee,
      };
    } catch (error) {
      return {
        fastestFee: 10,
        halfHourFee: 10,
        hourFee: 10,
      };
    }
  }

  static async getTxStatus(
    ticket: Pick<Ticket, "ticket_id">,
  ): Promise<TxStatus> {
    try {
      if (!ticket.ticket_id) {
        throw new Error("Transaction hash is required");
      }
      const tx = await fetch(
        `${bitcoinIndexerApi}/tx/${ticket.ticket_id}`,
      ).then((res) => res.json());
      return !!tx ? "success" : "pending";
    } catch (error) {
      return "pending";
    }
  }

  static validateAddress(addr: string): boolean {
    return validate(addr, Network.mainnet);
  }

  getFeeToken() {
    return {
      symbol: "BTC",
      decimals: 8,
    };
  }
}
