import { EventType, WalletType } from "./types";
import * as PubSub from "pubsub-js";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  HStack,
  Image,
  Text,
  VStack,
  Badge,
  useToast,
  useColorModeValue,
} from "@chakra-ui/react";
import { PlugWalletLogo, BitfinityWalletLogo } from "./Logos";
import CloseButtonForModal from "@components/common/CloseButtonForModal";
import { TOAST_ERROR_DURATION } from "src/utils/constants";

type Wallet = {
  type: WalletType;
  logo: any;
  detected?: boolean;
  onClick: () => Promise<void>;
};

export default function ICPWalletKitModal({
  open,
  onClose,
  whitelist,
}: {
  open: boolean;
  onClose: () => void;
  whitelist: string[];
}) {
  const toast = useToast();
  const textColor = useColorModeValue("gray.800", "gray.100");
  const boxWrapperBg = useColorModeValue("gray.200", "gray.800");

  const bitfinityWallet = window?.ic?.bitfinityWallet;

  const wallets: Wallet[] = [
    {
      type: WalletType.Plug,
      logo: PlugWalletLogo,
      detected: !!window?.ic?.plug,
      onClick: async () => {
        if (!window?.ic?.plug) {
          // open plug wallet website
          window.open("https://plugwallet.ooo", "_blank");
          return;
        }

        const publicKey = await window.ic.plug.requestConnect({
          whitelist,
        });
        if (!publicKey) {
          throw new Error("No Plug Account");
        }

        PubSub?.publish(EventType.ON_CONNECT, {
          address: window.ic.plug.principalId,
          connector: WalletType.Plug,
        });
      },
    },
    {
      type: WalletType.Bitfinity,
      logo: BitfinityWalletLogo,
      detected: !!bitfinityWallet,
      onClick: async () => {
        if (!bitfinityWallet) {
          window.open(
            "https://chrome.google.com/webstore/detail/bitfinity-wallet/jnldfbidonfeldmalbflbmlebbipcnle",
            "_blank",
          );
          return;
        }

        const publicKey = await bitfinityWallet.requestConnect({
          whitelist,
        });
        if (!publicKey) {
          throw new Error("No Bitfinity Account");
        }
        const principal = await bitfinityWallet.getPrincipal();
        const principalIdText = principal.toText();
        PubSub?.publish(EventType.ON_CONNECT, {
          address: principalIdText,
          connector: WalletType.Bitfinity,
        });
      },
    },
  ];

  return (
    <Modal isOpen={open} isCentered onClose={onClose}>
      <ModalOverlay />
      <ModalContent
        borderRadius={8}
        color={textColor}
        margin={{ base: 0 }}
        alignSelf={{ base: "flex-end", md: "center" }}
      >
        <ModalHeader color={textColor}>Connect ICP Wallet</ModalHeader>
        <CloseButtonForModal />
        <ModalBody pb={6}>
          {wallets.map((wallet) => {
            return (
              <HStack
                key={wallet.type}
                gap={4}
                px={4}
                py={2}
                borderRadius={8}
                cursor="pointer"
                onClick={async () => {
                  try {
                    await wallet.onClick();
                    onClose();
                  } catch (error) {
                    toast({
                      description: (error as Error).message,
                      status: "error",
                      duration: TOAST_ERROR_DURATION,
                    });
                  }
                }}
                _hover={{
                  bg: boxWrapperBg,
                }}
              >
                <Image
                  src={wallet.logo}
                  alt={wallet.type}
                  boxSize={16}
                  borderRadius={8}
                  objectFit="contain"
                />
                <VStack alignItems="flex-start" gap={0}>
                  <Text fontWeight={600} fontSize={24} color={textColor}>
                    {wallet.type}
                  </Text>
                  {wallet.detected && (
                    <Badge colorScheme="purple">INSTALLED</Badge>
                  )}
                </VStack>
              </HStack>
            );
          })}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
