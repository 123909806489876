import useServices from "@hooks/useServices";
import { TabAction, Token } from "@types";
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useTransferContext } from "./TransferContext";
import { useHubContext } from "./OmnityHubContext";
import { FETCH_TOKEN_INTERVAL } from "@utils/constants";

interface TokenListContextProps {
  tokens: Token[];
  isLoading: boolean;
  isLoadingList: boolean;
  updateTokens: () => void;
}

const initialState: TokenListContextProps = {
  tokens: [],
  isLoading: false,
  isLoadingList: false,
  updateTokens: () => {},
};

const TokenListContext = createContext<TokenListContextProps>(initialState);

export function useTokenList() {
  return useContext(TokenListContext);
}

export function TokenListProvider(props: { children: ReactNode }) {
  const [tokens, setTokens] = useState<Token[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingList, setIsLoadingList] = useState(false);

  const { sourceService, sourceAddr, sourceChain } = useServices();
  const { token, onTokenChange } = useTransferContext();
  const { tabAction } = useHubContext();

  const fetchTokenList = useCallback(async () => {
    return (await sourceService?.fetchTokens([], sourceAddr)) ?? [];
  }, [sourceChain, sourceAddr, sourceService?.chain.chain_id]);

  useEffect(() => {
    if (sourceAddr && sourceService && tabAction !== TabAction.Mint) {
      if (token?.id) {
        setIsLoading(true);
        sourceService
          ?.fetchTokens([token?.token_id], sourceAddr)
          .then((tokens) => {
            // @ts-ignore
            if (tokens[0] && tabAction !== TabAction.Mint) {
              onTokenChange(tokens[0]);
            }
            setIsLoading(false);
          });
      }
    }
  }, [sourceAddr, token?.id, sourceChain, tabAction]);

  useEffect(() => {
    setTokens(sourceService?.chain.token_list ?? []);
    setIsLoadingList(true);
    function fetchTask() {
      fetchTokenList().then((tokens) => {
        if (sourceService?.chain.chain_name === sourceChain) {
          setTokens(tokens);
        }
        setIsLoadingList(false);
      });
    }

    fetchTask();
    const tick = setInterval(fetchTask, FETCH_TOKEN_INTERVAL);
    return () => clearInterval(tick);
  }, [sourceChain, sourceAddr, sourceService?.chain.chain_id]);

  function updateTokens() {
    setIsLoadingList(true);
    fetchTokenList().then((tokens) => {
      setTokens(tokens);
      const _token = tokens.find((t) => t.id === token?.id);
      if (_token) {
        onTokenChange(_token);
      }
      setIsLoadingList(false);
    });

    // setIsLoading(true);
    // if (token?.token_id) {
    //   sourceService
    //     ?.fetchTokens([token?.token_id], sourceAddr)
    //     .then((tokens) => {
    //       if (tokens[0]) {
    //         onTokenChange(tokens[0]);
    //       }
    //       setIsLoading(false);
    //     });
    // }
  }

  return (
    <TokenListContext.Provider
      value={{ tokens, isLoading, isLoadingList, updateTokens }}
    >
      {props.children}
    </TokenListContext.Provider>
  );
}
