import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import { ChainID, ChainName, OmnityWidgetProps, TabAction } from "./types";
import { HubProvider } from "@context/OmnityHubContext";
import posthog from "posthog-js";
import WalletKitProvider from "./wallet-kits";
import { DEFAULT_TOKEN } from "@utils/constants";
import Doge from "./Doge";

posthog.init("phc_UtXfkZaaPLBCmniOY2tuSDGj8bCkTiIuGp0ZnAuFBUO", {
  api_host: "https://us.i.posthog.com",
  person_profiles: "always",
  enable_recording_console_log: false,
  disable_session_recording: true,
});

export default function DogeWidget({
  assetType,
  customs = ChainID.Doge,
  sourceChain = ChainName.Bitcoin,
  targetChain = ChainName.ICP,
  tokenIds,
  network = "mainnet",
  colorMode = "system",
  width = 540,
  reversible = false,
  isPeerToPeer = false,
  tab = TabAction.Transfer,
  onTabChange,
}: OmnityWidgetProps) {
  const _tab = tab ?? TabAction.Transfer;
  const _tokenIds =
    tokenIds && tokenIds.length ? tokenIds : [DEFAULT_TOKEN[customs]];
  const mainWidget = (
    <WalletKitProvider>
      <HubProvider
        assetType={assetType}
        customs={customs}
        network={network}
        tab={_tab}
        onTabChange={onTabChange}
      >
        <Doge
          customs={customs}
          width={width}
          sourceChain={sourceChain}
          targetChain={targetChain}
          tokenIds={_tokenIds}
          reversible={reversible}
          isPeerToPeer={isPeerToPeer}
          tab={_tab}
          onTabChange={onTabChange}
        />
      </HubProvider>
    </WalletKitProvider>
  );

  const config = {
    initialColorMode: colorMode,
    useSystemColorMode: colorMode === "system" ? true : false,
  };

  const theme = extendTheme({ config });

  return <ChakraProvider theme={theme}>{mainWidget}</ChakraProvider>;
}
