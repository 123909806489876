import { ReactNode } from "react";
import { BTCWalletProvider } from "@wallet-kits/btc-wallet-kit";
import { ICPWalletProvider } from "@wallet-kits/icp-wallet-kit";
import { EVMWalletKitProvider } from "@wallet-kits/evm-wallet-kit";
import { SOLWalletProvider } from "@wallet-kits/sol-wallet-kit";
import { OSMWalletProvider } from "@wallet-kits/osm-wallet-kit";
import { TonWalletProvider } from "@wallet-kits/ton-wallet-kit";
import { SuiWalletKitProvider } from "@wallet-kits/sui-wallet-kit";

export default function WalletKitProvider({
  children,
}: {
  children: ReactNode;
}) {
  return (
    <BTCWalletProvider>
      <ICPWalletProvider>
        <EVMWalletKitProvider>
          <SOLWalletProvider>
            <SuiWalletKitProvider>
              <TonWalletProvider>
                <OSMWalletProvider>{children}</OSMWalletProvider>
              </TonWalletProvider>
            </SuiWalletKitProvider>
          </SOLWalletProvider>
        </EVMWalletKitProvider>
      </ICPWalletProvider>
    </BTCWalletProvider>
  );
}
