import { chakra, Tooltip } from "@chakra-ui/react";
import { Info } from "lucide-react";

const InfoIcon = chakra(Info);

export default function InfoTip({
  tip,
  size = 16,
}: {
  tip: string;
  size?: number;
}) {
  return (
    <Tooltip label={tip} fontSize="md">
      <InfoIcon color="gray" size={size} />
    </Tooltip>
  );
}
