import { useConnection, useWallet } from "@solana/wallet-adapter-react";
import { PublicKey, SystemProgram, Transaction } from "@solana/web3.js";
import "@solana/wallet-adapter-react-ui/styles.css";
import { createBurnInstruction, TOKEN_PROGRAM_ID } from "@solana/spl-token";
import { createMemoInstruction } from "@solana/spl-memo";
import { SOLWalletTransferParams } from "./types";

export function useSOLWalletKit() {
  const { publicKey, signTransaction, sendTransaction, signAllTransactions } =
    useWallet();
  const { connection } = useConnection();

  const transfer = async ({
    feeAccount,
    feeAmount,
    amount,
    token,
    tokenAccountAddress,
    memo,
    fromAddress,
  }: SOLWalletTransferParams) => {
    if (!publicKey) {
      throw new Error("Wallet not connected");
    }
    if (!signTransaction || !sendTransaction || !signAllTransactions) {
      throw new Error("Wallet does not support transaction signing");
    }

    if (fromAddress !== publicKey.toString()) {
      throw new Error("Wallet address does not match");
    }

    const balance = await connection.getBalance(publicKey);
    if (BigInt(balance) <= feeAmount) {
      throw new Error("Insufficient $SOL balance");
    }

    const tx = new Transaction();

    tx.add(
      SystemProgram.transfer({
        fromPubkey: publicKey,
        toPubkey: new PublicKey(feeAccount),
        lamports: feeAmount,
      }),
    );

    if (tokenAccountAddress) {
      tx.add(
        createBurnInstruction(
          new PublicKey(tokenAccountAddress),
          new PublicKey(token.id),
          publicKey,
          amount,
          [],
          TOKEN_PROGRAM_ID,
        ),
      );
    }

    if (memo) {
      tx.add(createMemoInstruction(memo));
    }

    const signature = await sendTransaction(tx, connection);
    return signature;
  };

  return {
    address: publicKey?.toString(),
    transfer,
  };
}
