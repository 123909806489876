import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  useDisclosure,
  Button,
  ModalBody,
  useColorModeValue,
  useToast,
  VStack,
  usePrefersReducedMotion,
  useSteps,
  Step,
  StepDescription,
  StepIcon,
  StepIndicator,
  StepNumber,
  StepSeparator,
  StepStatus,
  StepTitle,
  Stepper,
  Box,
  Text,
  HStack,
  Collapse,
} from "@chakra-ui/react";
import { keyframes } from "@emotion/react";
import { useEffect, useState } from "react";
import { useTransferContext } from "../../context/TransferContext";
import {
  ChainName,
  FeeType,
  OnBridgeParams,
  ServiceType,
  Ticket,
  TicketInteractStep,
  TicketAction,
  ChainID,
  BridgeFee,
  GenerateTicketResult,
} from "@types";
import { formatFee, parseAmount } from "../../utils/format";
import ChainToChain from "./ChainToChain";
import useTickets from "@hooks/useTickets";
import { useBTCWalletKit } from "@wallet-kits/btc-wallet-kit";
import useFeeRate from "@hooks/useFeeRate";
import TicketTxStatus from "@components/TicketTxStatus";
import { useICPWalletKit } from "@wallet-kits/icp-wallet-kit";
import CloseButtonForModal from "@components/common/CloseButtonForModal";
import { useSOLWalletKit } from "@wallet-kits/sol-wallet-kit";
import { getServiceTypeFromChainId } from "src/utils/chains";
import TxSubmitButton from "./TxSubmitButton";
import posthog from "posthog-js";
import { useHubContext } from "@context/OmnityHubContext";
import { useTonWalletKit } from "@wallet-kits/ton-wallet-kit";
import { useOSMWalletKit } from "@wallet-kits/osm-wallet-kit";
import { TOAST_ERROR_DURATION } from "src/utils/constants";
import useServices from "@hooks/useServices";
import { useTokenList } from "@context/TokenListProvider";
import { useSuiWalletKit } from "@wallet-kits/sui-wallet-kit";

enum BridgeStep {
  Preview,
  Processing,
}

const ModalTitle = {
  [BridgeStep.Preview]: "Preview of Transaction",
  [BridgeStep.Processing]: "Processing",
};

const Fees = [
  {
    label: "Hour",
    value: FeeType.Hour,
  },
  {
    label: "Half Hour",
    value: FeeType.HalfHour,
  },
  {
    label: "Fastest",
    value: FeeType.Fastest,
  },
];

const pulse = keyframes`
  from { box-shadow: 0 0 0 0px rgba(152, 92, 221, 0.5); }
  to { box-shadow: 0 0 0 10px rgba(152, 92, 221, 0); }
`;

export default function ConfirmTransfer() {
  const {
    sourceChain,
    targetChain,
    sourceAddr,
    amount,
    token,
    targetAddr,
    onAmountChange,
  } = useTransferContext();
  const { updateTokens } = useTokenList();
  const [step, setStep] = useState(BridgeStep.Preview);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [currentTicket, setCurrentTicket] = useState<Ticket>();
  const [interactStep, setInteractStep] = useState(TicketInteractStep.START);
  const [bridgeFee, setBridgeFee] = useState<BridgeFee>();

  const [needRetry, setNeedRetry] = useState(false);

  const { transferRunes, transferBrc20 } = useBTCWalletKit();
  const { transfer, createActor } = useICPWalletKit();
  const { transfer: transferSol } = useSOLWalletKit();
  const { transfer: transferTon } = useTonWalletKit();
  const { transfer: transferOSM } = useOSMWalletKit();
  const { sendTx: transferSui } = useSuiWalletKit();
  const { feeRate, setFeeRate } = useFeeRate();

  const { feeTokenPrices } = useHubContext();
  const { sourceService, targetService } = useServices();
  useEffect(() => {
    if (sourceService && targetService?.chain.chain_id) {
      sourceService
        .getBridgeFee(targetService?.chain.chain_id, token)
        .then(setBridgeFee)
        .catch(console.error);
    }
  }, [
    sourceService?.chain.chain_id,
    targetService?.chain.chain_id,
    token?.token_id,
  ]);

  const toast = useToast();
  const _onClose = () => {
    onAmountChange("");
    setStep(BridgeStep.Preview);
    setNeedRetry(false);
    setActiveStep(0);
    setInteractStep(TicketInteractStep.START);
    onClose();
  };
  const textColor = useColorModeValue("gray.800", "gray.100");
  const boxWrapperHoverBg = useColorModeValue("gray.100", "gray.900");
  const prefersReducedMotion = usePrefersReducedMotion();

  const animation = prefersReducedMotion
    ? undefined
    : `${pulse} infinite 1s linear`;

  const { addTicket, updateTicket } = useTickets();
  const steps = sourceService?.getBridgeSteps(token) ?? [];

  const { activeStep, setActiveStep } = useSteps({
    index: 0,
    count: steps.length,
  });

  const onConfirmBefore = () => {
    if (sourceChain === ChainName.Bitcoin && token && token.composed_balance) {
      const parsedAmount = parseAmount(amount, token.decimals);
      if (
        token.composed_balance.available < parsedAmount &&
        token.balance >= parsedAmount
      ) {
        toast({
          description:
            "Insufficient balance: you have some unconfirmed balance, please wait for confirmation",
          status: "error",
          duration: TOAST_ERROR_DURATION,
        });
        return;
      }
    }
    onOpen();
  };

  const serviceType = getServiceTypeFromChainId(targetService?.chain.chain_id!);
  const ticketType =
    serviceType === ServiceType.Customs
      ? TicketAction.Redeem
      : TicketAction.Transfer;

  const onConfirm = async () => {
    try {
      setStep(BridgeStep.Processing);
      setNeedRetry(false);
      if (!sourceChain) {
        throw new Error("Please select a source chain");
      }
      if (!sourceService) {
        throw new Error("Failed to get source service");
      }
      if (!targetService) {
        throw new Error("Failed to get target service");
      }
      if (!sourceAddr) {
        throw new Error("Please connect your wallet first");
      }
      if (!targetAddr) {
        throw new Error("Please fill receiver address.");
      }
      if (!token) {
        throw new Error("Please select a token first");
      }

      const parsedAmount = parseAmount(amount, token.decimals);
      if (parsedAmount === 0n) {
        throw new Error("Please enter a valid amount");
      }

      let _feeRate = 0;
      if (sourceChain === ChainName.Bitcoin) {
        if (!feeRate) {
          throw new Error("Failed to get fee rate");
        }
        _feeRate = feeRate[feeRate.selected];
      }

      const params: OnBridgeParams = {
        token,
        sourceAddr,
        targetAddr,
        targetChainId: targetService.chain.chain_id,
        setStep: setActiveStep,
        amount: parsedAmount,
        feeRate: _feeRate,
      };
      if (sourceChain === ChainName.Bitcoin) {
        if (token.token_id.startsWith(ChainID.BitcoinBrc20)) {
          params.transfer = transferBrc20;
        } else {
          params.transfer = transferRunes;
        }
      } else if (sourceChain === ChainName.ICP) {
        params.createActor = createActor;
        params.transfer = transfer;
      } else if (sourceChain === ChainName.Solana) {
        params.transfer = transferSol;
      } else if (sourceChain === ChainName.Ton) {
        params.transfer = transferTon;
      } else if (sourceChain === ChainName.Osmosis) {
        params.transfer = transferOSM;
      } else if (sourceChain === ChainName.Sui) {
        params.transfer = transferSui;
      }
      setInteractStep(TicketInteractStep.SEND_TX);
      const result = await sourceService.onBridge(params);

      let ticket_id = result;
      let finalized = false;
      if (sourceChain === ChainName.ICP) {
        setInteractStep(TicketInteractStep.COMPLETED);
        finalized = true;
      } else {
        setInteractStep(TicketInteractStep.WAIT_TX);
      }

      const _ticket: Ticket = {
        type: ticketType,
        token: token.token_id,
        dst_chain: targetService.chain.chain_id,
        src_chain: sourceService.chain.chain_id,
        ticket_id,
        decimals: token.decimals,
        symbol: token.symbol,
        amount: parsedAmount.toString(),
        receiver: targetAddr,
        sender: sourceAddr,
        ticket_time: Date.now() * 1000000,
        finalized,
      };
      posthog.capture("new ticket", { ..._ticket });
      setCurrentTicket(_ticket);
      setTimeout(updateTokens, 10000);
      addTicket(_ticket);
    } catch (error) {
      setNeedRetry(true);

      let msg = (error as Error).message;
      if (error instanceof Error) {
        if (
          error.message.toLowerCase().includes("cardinal") ||
          error.message === "InsufficientFunds"
        ) {
          msg = "Insufficient BTC balance";
        }
      }
      toast({
        description: msg,
        status: "error",
        duration: TOAST_ERROR_DURATION,
      });
    }
  };

  return (
    <>
      <Button
        colorScheme="blue"
        cursor="pointer"
        w="98%"
        fontSize={22}
        py={8}
        borderRadius={8}
        onClick={onConfirmBefore}
      >
        Confirm
      </Button>
      <Modal
        isCentered
        closeOnOverlayClick={false}
        isOpen={isOpen}
        onClose={_onClose}
      >
        <ModalOverlay />
        <ModalContent
          p={0}
          borderRadius={8}
          color={textColor}
          margin={{ base: 0 }}
          alignSelf={{ base: "flex-end", md: "center" }}
        >
          {step === BridgeStep.Preview && <CloseButtonForModal />}
          <ModalHeader>{ModalTitle[step]}</ModalHeader>
          <ModalBody pb={6}>
            <VStack gap={{ base: 2, md: 1 }}>
              <ChainToChain
                broadcasting={interactStep === TicketInteractStep.SEND_TX}
                showAddr={step === BridgeStep.Preview}
                isCompelted={interactStep === TicketInteractStep.COMPLETED}
                sourceChain={sourceChain}
                targetChain={targetChain}
                sourceAddr={sourceAddr}
                targetAddr={targetAddr}
                amount={amount}
                token={token}
                fee={formatFee(bridgeFee, feeTokenPrices)}
              />
              {step === BridgeStep.Preview &&
                sourceChain === ChainName.Bitcoin &&
                feeRate && (
                  <VStack w="100%" alignItems="flex-start" mb={4}>
                    <HStack gap={0}>
                      <Text color="#999">Fee Rate(sat/vB)</Text>
                    </HStack>
                    <HStack w="98%">
                      {Fees.map((fee) => {
                        const isActive = fee.value === feeRate.selected;
                        return (
                          <VStack
                            key={fee.value}
                            flex={1}
                            borderWidth={0.5}
                            borderColor="gray.500"
                            gap={1}
                            borderRadius={4}
                            py={4}
                            bg={isActive ? boxWrapperHoverBg : undefined}
                            cursor="pointer"
                            onClick={() =>
                              setFeeRate({ ...feeRate, selected: fee.value })
                            }
                          >
                            <Text lineHeight={1} fontSize={20} fontWeight={600}>
                              {feeRate[fee.value]}
                            </Text>
                            <Text lineHeight={1} color="gray.500">
                              {fee.label}
                            </Text>
                          </VStack>
                        );
                      })}
                    </HStack>
                  </VStack>
                )}
              <HStack w="98%" alignItems="flex-start">
                <Collapse
                  in={step === BridgeStep.Processing}
                  animateOpacity
                  style={{ width: "100%" }}
                >
                  <Stepper
                    index={activeStep}
                    w="98%"
                    px={4}
                    py={4}
                    orientation="vertical"
                    gap={0}
                  >
                    {steps.map((step, index) => {
                      const isActive = index === activeStep;
                      return (
                        <Step key={index}>
                          <StepIndicator
                            animation={isActive ? animation : undefined}
                            boxShadow={
                              isActive ? "0px 0px 1px 1px #0000001a" : ""
                            }
                          >
                            <StepStatus
                              complete={<StepIcon />}
                              incomplete={<StepNumber />}
                              active={<StepNumber />}
                            />
                          </StepIndicator>

                          <Box flexShrink="0" pb={4}>
                            <StepTitle>{step.title}</StepTitle>
                            <StepDescription>
                              {step.description}
                            </StepDescription>
                          </Box>

                          <StepSeparator />
                        </Step>
                      );
                    })}
                  </Stepper>
                </Collapse>
              </HStack>

              {currentTicket && (
                <TicketTxStatus
                  service={sourceService}
                  ticket={currentTicket}
                  onReverted={() => {
                    setActiveStep(0);
                    setNeedRetry(true);
                  }}
                  onGenerateTicket={() =>
                    setInteractStep(TicketInteractStep.GEN_TICKET)
                  }
                  onCompleted={(result: GenerateTicketResult) => {
                    setInteractStep(TicketInteractStep.COMPLETED);
                    setActiveStep(steps.length);
                    updateTicket({ ...result.ticket });
                    setCurrentTicket({ ...result.ticket });
                  }}
                />
              )}

              <TxSubmitButton
                step={interactStep}
                onConfirm={onConfirm}
                onClose={_onClose}
                ticketType={ticketType}
                needRetry={needRetry}
              />
            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
