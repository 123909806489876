const PREFIX = "BWK";

export enum EventType {
  ON_SHOW_MODAL = PREFIX + "ON_SHOW_MODAL",
  ON_HIDE_MODAL = PREFIX + "ON_HIDE_MODAL",
}

export interface TransferRunesParams {
  wallet_address: string;
  receiver_address: string;
  rune_name: string;
  fee_rate: number;
  amount: string;
  platform_fee?: bigint;
  platform_fee_collector?: string;
}

export interface TransferBrc20Params {
  sender: string;
  receiver: string;
  tick: string;
  fee_rate: number;
  amount: string;
  deposit_addr: string;
  deposit_public: string;
  platform_fee?: number;
  platform_fee_collector?: string;
}
