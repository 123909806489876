import {
  Token,
  BridgeStep,
  OnBridgeParams,
  OnBurnParams,
  Ticket,
  TicketStatusResult,
  ChainID,
  BridgeFee,
  GenerateTicketResult,
  Chain,
} from "@types";
import BaseService from "./BaseService";
import { DEFAULT_TOKEN } from "src/utils/constants";
import { createActor } from "./candids";
import { idlFactory, _SERVICE } from "./candids/DogeCustoms.did";
import { ActorSubclass } from "@dfinity/agent";

export default class DogeCustomsService extends BaseService {
  static CANISTER_ID = "nnqqa-aaaaa-aaaar-qamla-cai";
  actor: ActorSubclass<_SERVICE>;

  constructor(chain: Chain) {
    super(chain);
    this.actor = createActor<_SERVICE>(chain.canister_id, idlFactory);
  }

  async getTokenList(): Promise<Token[]> {
    const token: Token = {
      id: "doge",
      name: "DOGE",
      symbol: "DOGE",
      decimals: 8,
      icon: "https://cryptologos.cc/logos/dogecoin-doge-logo.svg",
      balance: 0n,
      token_id: DEFAULT_TOKEN[ChainID.Doge],
      fee: 0n,
      chain_id: ChainID.Doge,
    };

    return Promise.resolve([token]);
  }

  async fetchTokens(token_ids?: string[], address?: string): Promise<Token[]> {
    return this.chain.token_list || [];
  }

  getBridgeSteps(token?: Token): BridgeStep[] {
    return [
      {
        title: "Transfer",
        description: "Send your BTC",
      },
    ];
  }

  static async getDepositAddress(
    targetAddr: string,
    targetChainId: ChainID,
  ): Promise<string> {
    const actor = createActor<_SERVICE>(
      DogeCustomsService.CANISTER_ID,
      idlFactory,
    );
    const result = await actor.get_deposit_address(targetChainId, targetAddr);
    if ("Ok" in result) {
      return result.Ok;
    }
    throw new Error(Object.keys(result.Err)[0]);
  }

  static async submitTxid({
    txid,
    target_chain_id,
    receiver,
  }: {
    txid: string;
    receiver: string;
    target_chain_id: ChainID;
  }) {
    const actor = createActor<_SERVICE>(
      DogeCustomsService.CANISTER_ID,
      idlFactory,
    );
    const result = await actor.generate_ticket({
      token_id: "dogecoin-native-DOGE",
      txid,
      target_chain_id,
      receiver,
    });
    if ("Err" in result) {
      throw new Error(Object.keys(result.Err)[0]);
    }
  }

  async onBridge(params: OnBridgeParams): Promise<string> {
    const { setStep, transfer } = params;

    let tx_hash: string = "";
    if (!transfer) {
      throw new Error("Transfer function is required");
    }

    setStep && setStep(1);

    return tx_hash;
  }
  onBurn(params: OnBurnParams): Promise<string> {
    throw new Error("Method not implemented.");
  }
  onMint(params: OnBridgeParams): Promise<string> {
    throw new Error("Method not implemented.");
  }
  generateTicket(ticket: Ticket): Promise<GenerateTicketResult> {
    throw new Error("Method not implemented.");
  }
  getTicketStatus(ticket_id: string): Promise<TicketStatusResult> {
    throw new Error("Method not implemented.");
  }
  static validateAddress(addr: string): boolean {
    const re = "D{1}[5-9A-HJ-NP-U]{1}[1-9A-HJ-NP-Za-km-z]{32}";
    return new RegExp("(?:^" + re + "$)").test(addr);
  }

  getBridgeFee(targetChainId: ChainID, token?: Token): Promise<BridgeFee> {
    return Promise.resolve({
      fee: 0n,
      symbol: "DOGE",
      decimals: 8,
    });
  }

  getFeeToken() {
    return {
      symbol: "DOGE",
      decimals: 8,
    };
  }
}
