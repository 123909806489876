import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  useDisclosure,
  VStack,
  HStack,
  Text,
  useColorModeValue,
  Box,
  useToast,
} from "@chakra-ui/react";
import { ReactNode, createContext, useContext, useMemo, useState } from "react";
import { useTransferContext } from "./TransferContext";
import ChainLogo from "../components/common/ChainLogo";
import { useHubContext } from "./OmnityHubContext";
import { AssetType, ChainName, ChainState, TabAction } from "../types";
import { getAvailableChainName } from "@utils/chains";
import ItemSelected from "@components/common/ItemSelected";
import CloseButtonForModal from "@components/common/CloseButtonForModal";
import {
  NON_BURNABLE_L2_CHAINS,
  NON_MINTABLE_L2_CHAINS,
  TEMPORARY_DISABLED_CHAINS,
  TOAST_ERROR_DURATION,
} from "src/utils/constants";

interface ChainSelectProviderProps {
  onShowChainsModal: (isSettingSourceChain: boolean) => void;
}

const initialState: ChainSelectProviderProps = {
  onShowChainsModal: () => {},
};

const ChainSelectContext =
  createContext<ChainSelectProviderProps>(initialState);

export function useChainContext() {
  return useContext(ChainSelectContext);
}

type ChainProviderProps = {
  children: ReactNode;
};

const HIDDEN_CHAINS: ChainName[] = [];

export function ChainSelectProvider({ children }: ChainProviderProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isSettingSourceChain, setIsSettingSourceChain] = useState(false);

  const {
    sourceChain,
    targetChain,
    token,
    isPeerToPeer,
    passedProps,
    onSourceChainChange,
    onTargetChainChange,
  } = useTransferContext();

  const toast = useToast();
  const textColor = useColorModeValue("gray.800", "gray.100");
  const boxWrapperBg = useColorModeValue("gray.200", "gray.800");
  const borderColor = useColorModeValue("gray.300", "gray.600");

  const context = {
    onShowChainsModal: (isSettingSourceChain: boolean) => {
      setIsSettingSourceChain(isSettingSourceChain);
      onOpen();
    },
  };

  const { chainList, chains, tabAction, assetType, theme } = useHubContext();

  const isBitfinity = theme === "bitfinity";

  const getBitfinityChains = (chain: ChainName) =>
    chain === ChainName.Bitfinity ||
    chain === ChainName.Bitcoin ||
    chain === ChainName.ICP;

  const visibleChains = chains.filter(
    (chain) => !HIDDEN_CHAINS.includes(chain.chain_name),
  );

  const filterChains = (chains: ChainName[], excludeChain?: ChainName) =>
    chains.filter(
      (c) => c !== excludeChain && (isBitfinity ? getBitfinityChains(c) : true),
    );

  const _chainsList = useMemo(() => {
    if (assetType === AssetType.ckbtc) {
      if (isSettingSourceChain || !sourceChain) {
        return chainList.filter((c) => c !== targetChain);
      }
      if (sourceChain === ChainName.Bitcoin) {
        return chainList.filter((c) => c !== ChainName.Bitcoin);
      }
      return [ChainName.Bitcoin];
    }
    // If it's bitfinity, only show bitfinity and bitcoin except source chain
    // Otherwise show all chains except source chain
    if (tabAction !== TabAction.Transfer) {
      return filterChains(chainList, sourceChain).filter((c) => {
        if (tabAction === TabAction.Mint) {
          return !NON_MINTABLE_L2_CHAINS.includes(c);
        }
        if (tabAction === TabAction.Burn) {
          return !NON_BURNABLE_L2_CHAINS.includes(c);
        }
        return true;
      });
    }

    if (isPeerToPeer) {
      const peerChains = [
        passedProps?.sourceChain,
        passedProps?.targetChain,
      ] as ChainName[];
      return filterChains(
        peerChains,
        isSettingSourceChain ? targetChain : sourceChain,
      );
    }

    if (isSettingSourceChain || !sourceChain) {
      return filterChains(chainList, targetChain);
    }

    const availableChains = getAvailableChainName(sourceChain, visibleChains);
    return filterChains(availableChains, sourceChain);
  }, [
    assetType,
    tabAction,
    isSettingSourceChain,
    sourceChain,
    targetChain,
    visibleChains.length,
    token?.chain_id,
    isPeerToPeer,
  ]);

  const _selected = isSettingSourceChain ? sourceChain : targetChain;

  return (
    <ChainSelectContext.Provider value={context}>
      {children}
      <Modal isCentered isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent
          p={0}
          borderRadius={8}
          color={textColor}
          margin={{ base: 0 }}
          alignSelf={{ base: "flex-end", md: "center" }}
        >
          <ModalHeader>Select chain</ModalHeader>
          <CloseButtonForModal />
          <ModalBody p={0}>
            <VStack maxH={300} overflowY="scroll" mt={2} gap={0}>
              {_chainsList
                .filter((c) => {
                  if (token) {
                    return visibleChains
                      .find((ch) => ch.chain_name === c)
                      ?.token_list?.some((t) => t.token_id === token.token_id);
                  }
                  return true;
                })
                .sort((a: ChainName, b: ChainName) => a.localeCompare(b))
                .sort((a: ChainName, b: ChainName) => {
                  if (
                    [
                      ChainName.Bitcoin,
                      ChainName.Solana,
                      ChainName.Ethereum,
                      ChainName.ICP,
                      ChainName.BitLayer,
                    ].includes(a)
                  ) {
                    return -1;
                  }
                  return 1;
                })
                .map((c, idx) => {
                  const isLast = idx === _chainsList.length - 1;
                  const chain = visibleChains.find(
                    (ch) => ch.chain_name.toLowerCase() === c.toLowerCase(),
                  );
                  const isTemporaryDisabled = TEMPORARY_DISABLED_CHAINS.some(
                    (c) => c === chain?.chain_id,
                  );
                  const disabled =
                    chain?.chain_state === ChainState.Deactive ||
                    isTemporaryDisabled;

                  return (
                    <HStack
                      key={c}
                      w="100%"
                      py={4}
                      px={6}
                      cursor={disabled ? "not-allowed" : "pointer"}
                      opacity={disabled ? 0.5 : 1}
                      justifyContent="space-between"
                      borderBottomLeftRadius={isLast ? 8 : 0}
                      borderBottomRightRadius={isLast ? 8 : 0}
                      _hover={{
                        bg: boxWrapperBg,
                      }}
                      borderBottomWidth={0.5}
                      borderBottomColor={borderColor}
                      onClick={() => {
                        if (!chain) {
                          return;
                        }
                        if (disabled) {
                          return toast({
                            title: `Bitcoin can't be connected to ${c} currently`,
                            status: "error",
                            duration: TOAST_ERROR_DURATION,
                          });
                        }
                        switch (tabAction) {
                          case TabAction.Burn:
                          case TabAction.Mint:
                            onSourceChainChange(chain.chain_name);
                            break;
                          case TabAction.Transfer:
                            if (isSettingSourceChain) {
                              onSourceChainChange(chain.chain_name);
                            } else {
                              onTargetChainChange(chain.chain_name);
                            }
                            break;
                          default:
                            break;
                        }
                        onClose();
                      }}
                    >
                      <HStack>
                        <ChainLogo chain={c} size={40} />
                        <Text fontWeight={600} fontSize={24}>
                          {c}
                        </Text>
                      </HStack>
                      {disabled ? (
                        <Box bg="red.400" color="white" px={2} borderRadius={4}>
                          <Text fontSize={12}>{ChainState.Deactive}</Text>
                        </Box>
                      ) : _selected === c ? (
                        <ItemSelected size={24} />
                      ) : null}
                    </HStack>
                  );
                })}
            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </ChainSelectContext.Provider>
  );
}
