import { useAccount, useSwitchChain } from "wagmi";

export function useEVMWalletKit() {
  const { address, chainId } = useAccount();
  const { switchChain } = useSwitchChain();

  return {
    address,
    chainId,
    switchChain,
  };
}
