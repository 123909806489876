import { useCallback, useEffect, useState } from "react";
import PubSub from "pubsub-js";
import { EventType, TransferBrc20Params, TransferRunesParams } from "./types";
import { UNISAT, useLaserEyes } from "@omnisat/lasereyes";
import { bitcoinIndexerApi } from "./constants";

async function getTxStatus(hash: string): Promise<"success" | "pending"> {
  try {
    if (!hash) {
      throw new Error("Transaction hash is required");
    }
    const tx = await fetch(`${bitcoinIndexerApi}/tx/${hash}`).then((res) =>
      res.json(),
    );
    return !!tx ? "success" : "pending";
  } catch (error) {
    return "pending";
  }
}

async function pollStatus(txid: string, interval = 1000, timeout = 60000) {
  const startTime = Date.now();

  return new Promise((resolve, reject) => {
    const poll = async () => {
      if (Date.now() - startTime >= timeout) {
        return reject(new Error("Checking tx status timed out"));
      }

      const status = await getTxStatus(txid);
      if (status === "success") {
        return resolve(true);
      }

      setTimeout(poll, interval);
    };

    poll();
  });
}

export function useBTCWalletKit() {
  const {
    address,
    connect,
    disconnect,
    signPsbt,
    pushPsbt,
    provider,
    sendBTC,
  } = useLaserEyes();
  const [balance, setBalance] = useState(0n);

  useEffect(() => {
    if (address) {
      fetch(`${bitcoinIndexerApi}/address/${address}`)
        .then((res) => res.json())
        .then((data) => {
          const availableBalance =
            data.chain_stats.funded_txo_sum - data.chain_stats.spent_txo_sum;
          setBalance(BigInt(availableBalance));
        })
        .catch(console.error);
    }
  }, [address]);

  const onShowModal = useCallback(() => {
    PubSub.publish(EventType.ON_SHOW_MODAL, {});
  }, []);

  const onHideModal = useCallback(() => {
    PubSub.publish(EventType.ON_HIDE_MODAL, {});
  }, []);

  const transferRunes = async (params: TransferRunesParams) => {
    await connect(provider);
    const result = await fetch(
      "https://runemint.mainnet.octopus.network/send",
      {
        method: "POST",
        body: JSON.stringify(params),
        headers: {
          "Content-Type": "application/json",
        },
      },
    ).then((res) => res.json());

    let psbtHex;
    if (result.code === 0) {
      psbtHex = result.data;
    } else {
      throw new Error(result.data);
    }

    const signedPsbt = await signPsbt(psbtHex, true, false);
    if (signedPsbt?.signedPsbtHex) {
      return pushPsbt(signedPsbt.signedPsbtHex);
    }
    throw new Error("Failed to sign PSBT");
  };

  const transferBrc20 = async (
    params: TransferBrc20Params,
    setStep: (step: number) => void,
  ) => {
    await connect(provider);
    const response = await fetch(
      "https://brc20-mint.mainnet.octopus.network/build",
      {
        method: "POST",
        body: JSON.stringify(params),
        headers: {
          "Content-Type": "application/json",
        },
      },
    ).then((res) => res.json());

    if (response.code !== 200) {
      throw new Error(response.err_msg);
    }
    const [inscribePsbt, revealTx, transferPsbt] = response.data;

    const signedInscribePsbt = await signPsbt(inscribePsbt, true, false);
    if (signedInscribePsbt?.signedPsbtHex) {
      await pushPsbt(signedInscribePsbt.signedPsbtHex);
      const revealTxid = await pushPsbt(revealTx);

      setStep && setStep(1);

      if (!(provider === UNISAT)) {
        if (!revealTxid) {
          throw new Error("Failed to reveal transaction");
        }
        await pollStatus(revealTxid);
        await new Promise((r) => setTimeout(r, 5000));
      }

      const signedTransferPsbt = await signPsbt(transferPsbt, true, false);
      if (signedTransferPsbt?.signedPsbtHex) {
        setStep && setStep(2);
        return pushPsbt(signedTransferPsbt.signedPsbtHex);
      }
      throw new Error("Failed to sign transfer PSBT");
    }

    throw new Error("Failed to sign inscribe PSBT");
  };

  return {
    address,
    onShowModal,
    onHideModal,
    onDisconnect: disconnect,
    transferRunes,
    transferBrc20,
    balance,
    sendBTC,
  };
}
