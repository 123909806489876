import { useAddRunesContext } from "@context/AddRunesContext";
import { useHubContext } from "@context/OmnityHubContext";
import { useTransferContext } from "@context/TransferContext";
import ServiceFactory from "@services/Factory";
import { AssetType, ChainName, ServiceType, TabAction } from "@types";
import { useMemo } from "react";

export default function useServices() {
  const { tabAction, assetType, chains } = useHubContext();
  const { executeChain } = useAddRunesContext();
  const { sourceChain, targetChain, sourceAddr } = useTransferContext();

  let chain: ChainName | undefined;
  let serviceType: ServiceType | undefined;
  let _sourceAddr;

  if (tabAction === TabAction.AddRunes) {
    chain = executeChain;
    serviceType = ServiceType.Route;
  } else {
    chain = sourceChain;
    _sourceAddr = sourceAddr;
    if (chain === ChainName.ICP) {
      serviceType =
        assetType === AssetType.icrc ? ServiceType.Customs : ServiceType.Route;
    }
  }

  const sourceService = useMemo(() => {
    const _chains = chains.filter((c) => {
      if (serviceType) {
        return c.chain_name === chain && c.service_type === serviceType;
      }
      return c.chain_name === chain;
    });

    if (_chains[0]) {
      return ServiceFactory.createService(_chains[0]);
    }
    return null;
  }, [chain, serviceType, chains.length]);

  const targetService = useMemo(() => {
    if (sourceService && targetChain) {
      const _targetChain = chains.find((c) => c.chain_name === targetChain);
      if (_targetChain) {
        return ServiceFactory.createService(_targetChain);
      }
    }
    return null;
  }, [targetChain, sourceService?.chain.chain_id]);

  return {
    sourceService,
    targetService,
    sourceChain: chain,
    sourceAddr: _sourceAddr,
  };
}
