import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Button,
  useDisclosure,
  useToast,
  VStack,
  HStack,
  Text,
  chakra,
  Box,
  Skeleton,
} from "@chakra-ui/react";
import { ChainName } from "../types";
import { useEffect, useState } from "react";
import QRCode from "react-qr-code";
import { Copy } from "lucide-react";
import BitcoinckBTCService from "@services/BitcoinckBTCService";
import { getChainIdFromName } from "src/utils/chains";
import {
  MIN_CKBTC_TRANSFER_AMOUNT,
  TOAST_ERROR_DURATION,
} from "src/utils/constants";
import { useTransferContext } from "@context/TransferContext";

const CopyIcon = chakra(Copy);

export default function DepositAddressModal() {
  const { sourceAddr, targetAddr, sourceChain, targetChain } =
    useTransferContext();
  const [confirming, setConfirming] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [ckbtcAddr, setCkBTCAddr] = useState<string>("");
  const [retrievingCkbtcAddr, setRetrievingCkbtcAddr] = useState(false);

  const isTransfer = sourceChain === ChainName.Bitcoin;

  const toast = useToast();

  useEffect(() => {
    if (isTransfer && targetChain && targetAddr) {
      setRetrievingCkbtcAddr(true);
      const chainId = getChainIdFromName(targetChain);
      if (chainId.length > 0) {
        BitcoinckBTCService.getDepositAddress(targetAddr ?? "", chainId[0]!)
          .then((addr) => {
            setCkBTCAddr(addr);
            setRetrievingCkbtcAddr(false);
          })
          .catch((error) => {
            toast({
              description: (error as Error).message,
              status: "error",
              duration: TOAST_ERROR_DURATION,
            });
            setRetrievingCkbtcAddr(false);
          });
      }
    }
  }, [targetAddr, sourceAddr, isTransfer, targetChain]);

  const onConfirm = async () => {
    setConfirming(true);
    try {
      if (!targetAddr || !ckbtcAddr) {
        throw new Error("Receiver address is required");
      }
      const chainId = getChainIdFromName(targetChain);
      await BitcoinckBTCService.updateBalanceAfterFinalization(
        targetAddr,
        chainId[0]!,
      );
      toast({
        description: `BTC is on the way to ${targetChain}, stay tuned`,
        status: "success",
        duration: 5000,
      });
      setConfirming(false);
      onClose();
    } catch (error) {
      toast({
        description: (error as Error).message,
        status: "error",
        duration: TOAST_ERROR_DURATION,
      });
    }
    setConfirming(false);
  };

  const disabled = retrievingCkbtcAddr || !ckbtcAddr;

  return (
    <>
      <Button
        w="100%"
        colorScheme="blue"
        size="lg"
        borderRadius={6}
        onClick={onOpen}
        disabled={disabled}
        opacity={disabled ? 0.7 : 1}
      >
        Show Deposit Address
      </Button>

      <Modal isCentered isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{isTransfer ? "Transfer" : "Redeem"}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack alignItems="flex-start" pb={4}>
              {isTransfer && (
                <VStack w="100%" gap={0} mt={6}>
                  {retrievingCkbtcAddr || !ckbtcAddr ? (
                    <VStack w="100%" alignItems="center">
                      <Skeleton height={168} width={168} />
                      <Skeleton height={6} width={280} />
                    </VStack>
                  ) : (
                    <VStack w="100%" alignItems="center">
                      <Box
                        bg="white"
                        p={2}
                        borderRadius={4}
                        height={168}
                        width={168}
                      >
                        {ckbtcAddr && (
                          <QRCode
                            value={ckbtcAddr}
                            size={152}
                            bgColor="white"
                          />
                        )}
                      </Box>
                      <HStack
                        cursor="pointer"
                        onClick={() => {
                          navigator.clipboard.writeText(ckbtcAddr);
                          toast({
                            title: "Copied",
                            status: "success",
                            duration: 3000,
                          });
                        }}
                      >
                        <Text
                          color="blue.400"
                          maxW={window.innerWidth * 0.78}
                          textAlign="center"
                          fontFamily="monospace"
                        >
                          {ckbtcAddr || "Missing Osmosis address"}
                        </Text>
                        <CopyIcon color="gray.300" size={16} />
                      </HStack>
                    </VStack>
                  )}

                  <Text fontSize={14} color="gray.400" mt={2}>
                    Please deposit BTC to this address,{" "}
                    <Text display="inline" fontWeight={600}>
                      {MIN_CKBTC_TRANSFER_AMOUNT} BTC
                    </Text>{" "}
                    at least.
                  </Text>
                </VStack>
              )}

              <Button
                colorScheme="blue"
                w="100%"
                borderRadius={2}
                onClick={onConfirm}
                mt={4}
                isLoading={retrievingCkbtcAddr || confirming}
                loadingText={
                  retrievingCkbtcAddr
                    ? "Generating Deposit Address"
                    : "Checking"
                }
              >
                I have deposited
              </Button>
            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
