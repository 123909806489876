import {
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  VStack,
} from "@chakra-ui/react";
import { ChainID, OmnityWidgetProps, TabAction } from "@types";
import Footer from "@components/common/Footer";
import { ChainSelectProvider } from "@context/ChainContext";
import { TransferProvider } from "@context/TransferContext";
import BitfinityTransfer from "./tabs/BitfinityTransfer";
import { useHubContext } from "@context/OmnityHubContext";
import { AddRunesProvider } from "@context/AddRunesContext";
import BitfinityAddRunes from "./tabs/BitfinityAddRunes";
import { TokenListProvider } from "@context/TokenListProvider";

const TABS = [TabAction.Transfer, TabAction.AddRunes];

export default function Bitfinity({
  width = 464,
  sourceChain,
  targetChain,
  tokenIds,
  reversible,
  isPeerToPeer,
  onTabChange,
  tab,
  customs,
}: OmnityWidgetProps) {
  const { onTabActionChange } = useHubContext();

  let tabs = TABS;
  if (customs === ChainID.sICP) {
    tabs = [];
  }

  return (
    <TransferProvider
      sourceChain={sourceChain}
      targetChain={targetChain}
      tokenIds={tokenIds}
      reversible={reversible}
      isPeerToPeer={isPeerToPeer}
    >
      <ChainSelectProvider>
        <TokenListProvider>
          <VStack
            id="omnity"
            w={{ base: "100%", md: width }}
            gap="2px"
            px={{ base: 2, md: 0 }}
            mt={{ base: 6, md: 0 }}
          >
            <Tabs
              variant="soft-rounded"
              w="100%"
              index={TABS.indexOf(tab ?? TabAction.Transfer)}
            >
              <TabList gap={{ base: 2, md: 4 }}>
                {tabs.map((tab) => {
                  return (
                    <Tab
                      key={tab}
                      color="text.secondary"
                      onClick={() => {
                        onTabActionChange && onTabActionChange(tab);
                        onTabChange && onTabChange(tab);
                      }}
                      _selected={{
                        bgColor: "primary.main",
                        color: "text.primary",
                      }}
                      _focus={{
                        borderWidth: 0,
                        outline: "none",
                        boxShadow: "none",
                      }}
                      _hover={{
                        bgColor: "bg.module",
                        color: "text.white",
                      }}
                      pos="relative"
                    >
                      {tab}
                    </Tab>
                  );
                })}
              </TabList>
              <TabPanels mt={2}>
                <TabPanel p={0}>
                  <BitfinityTransfer width={width} />
                </TabPanel>
                <TabPanel p={0}>
                  <AddRunesProvider>
                    <BitfinityAddRunes width={width} />
                  </AddRunesProvider>
                </TabPanel>
              </TabPanels>
            </Tabs>
            <Footer />
          </VStack>
        </TokenListProvider>
      </ChainSelectProvider>
    </TransferProvider>
  );
}
