import { HStack, Text, useColorModeValue, VStack } from "@chakra-ui/react";
import useFeeRate from "@hooks/useFeeRate";
import { FeeType } from "@types";

const Fees = [
  {
    label: "Hour",
    value: FeeType.Hour,
  },
  {
    label: "Half Hour",
    value: FeeType.HalfHour,
  },
  {
    label: "Fastest",
    value: FeeType.Fastest,
  },
];

export default function FeeRateSelector() {
  const { feeRate, setFeeRate } = useFeeRate();
  const boxWrapperHoverBg = useColorModeValue("gray.100", "gray.900");
  return (
    <VStack w="100%" alignItems="flex-start" mb={4}>
      <HStack gap={0}>
        <Text color="#999">Fee Rate(sat/vB)</Text>
      </HStack>
      <HStack w="98%">
        {Fees.map((fee) => {
          const isActive = fee.value === feeRate.selected;
          return (
            <VStack
              key={fee.value}
              flex={1}
              borderWidth={0.5}
              borderColor="gray.500"
              gap={1}
              borderRadius={4}
              py={4}
              bg={isActive ? boxWrapperHoverBg : undefined}
              cursor="pointer"
              onClick={() => setFeeRate({ ...feeRate, selected: fee.value })}
            >
              <Text lineHeight={1} fontSize={20} fontWeight={600}>
                {feeRate[fee.value]}
              </Text>
              <Text lineHeight={1} color="gray.500">
                {fee.label}
              </Text>
            </VStack>
          );
        })}
      </HStack>
    </VStack>
  );
}
