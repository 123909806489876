import { ReactNode, createContext, useEffect, useMemo, useState } from "react";
import { atomWithStorage } from "jotai/utils";
import { useAtom } from "jotai";
import PubSub from "pubsub-js";
import {
  EventType,
  OsmosisWalletKitProviderProps,
  TransferParams,
  WalletType,
} from "./types";
import OSMWalletKitModal from "./OSMWalletKitModal";
import { Window as KeplrWindow } from "@keplr-wallet/types";
import { GasPrice } from "@cosmjs/stargate";
import { CHAIN_INFO } from "./constants";
import { SigningCosmWasmClient } from "@cosmjs/cosmwasm-stargate";
import { Decimal } from "@cosmjs/math";
import { AssetType } from "@types";

declare global {
  // eslint-disable-next-line @typescript-eslint/no-empty-object-type
  interface Window extends KeplrWindow {}
}

export const OsmosisWalletKitProviderContext =
  createContext<OsmosisWalletKitProviderProps | null>(null);

const accountAtom = atomWithStorage<{
  address: string;
  connector?: WalletType;
}>("owk.account", {
  address: "",
  connector: undefined,
});

export function OSMWalletProvider({ children }: { children: ReactNode }) {
  const [account, setAccount] = useAtom(accountAtom);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const connectListener = PubSub?.subscribe(
      EventType.ON_CONNECT,
      (
        _: string,
        { address, connector }: { address: string; connector: WalletType },
      ) => {
        setAccount({ address, connector });
      },
    );
    return () => {
      PubSub?.unsubscribe(connectListener);
    };
  }, [setAccount]);

  const transfer = async (params: TransferParams) => {
    const keplr = window.keplr;
    if (!keplr) {
      throw new Error("Keplr extension not installed");
    }
    const {
      amount,
      fromAddr,
      toAddr,
      assetType,
      targetChainId,
      tokenId,
      denom,
      contractAddr,
    } = params;

    await keplr.experimentalSuggestChain(CHAIN_INFO);
    await keplr.enable(CHAIN_INFO.chainId);
    let offlineSigner: any = keplr.getOfflineSigner(CHAIN_INFO.chainId);
    const accounts = await offlineSigner.getAccounts();
    if (accounts.length === 0) {
      throw new Error("There is no available key");
    }
    const key = await keplr.getKey(CHAIN_INFO.chainId);
    const isLedger = key.isNanoLedger;
    if (isLedger) {
      offlineSigner = keplr.getOfflineSignerOnlyAmino(CHAIN_INFO.chainId);
    }
    const client = await SigningCosmWasmClient.connectWithSigner(
      CHAIN_INFO.rpc,
      offlineSigner,
      {
        gasPrice: new GasPrice(Decimal.one(1), "uosmo"),
      },
    );

    let txHash = "";

    const feeInfo = await client.queryContractSmart(contractAddr, {
      get_target_chain_fee: {
        target_chain: targetChainId,
      },
    });
    if (assetType === AssetType.doge) {
      const msg = {
        generate_ticket: {
          token_id: tokenId,
          sender: fromAddr,
          receiver: toAddr,
          amount: amount.toString(),
          target_chain: targetChainId,
          action: "redeem",
        },
      };
      const result = await client.execute(
        fromAddr,
        contractAddr,
        msg,
        "auto",
        "",
        [
          {
            denom: "uosmo",
            amount: `${feeInfo.fee_amount}`,
          },
        ],
      );
      txHash = result.transactionHash;
    } else if (assetType === AssetType.ckbtc) {
      const msg = {
        redeem_all_b_t_c: {
          token_id: tokenId,
          receiver: toAddr,
          amount: amount.toString(),
          target_chain: targetChainId,
        },
      };

      const result = await client.execute(
        fromAddr,
        contractAddr,
        msg,
        "auto",
        "",
        [
          {
            denom: denom,
            amount: amount.toString(),
          },
          {
            denom: "uosmo",
            amount: `${feeInfo.fee_amount}`,
          },
        ],
      );
      txHash = result.transactionHash;
    } else {
      const msg = {
        generate_ticket: {
          token_id: tokenId,
          sender: fromAddr,
          receiver: toAddr,
          amount: amount.toString(),
          target_chain: targetChainId,
          action: "redeem",
        },
      };

      const result = await client.execute(
        fromAddr,
        contractAddr,
        msg,
        "auto",
        "",
        [
          {
            denom: "uosmo",
            amount: `${feeInfo.fee_amount}`,
          },
        ],
      );
      txHash = result.transactionHash;
    }
    return txHash;
  };

  const contextValue = useMemo(
    () => ({
      address: account.address,
      connector: account.connector,
      onShowModal: () => {
        setShowModal(true);
      },
      onHideModal: () => {
        setShowModal(false);
      },
      onDisconnect: () => {
        setAccount({ address: "", connector: undefined });
      },
      transfer,
    }),
    [account.address, account.connector, setAccount],
  );

  return (
    <OsmosisWalletKitProviderContext.Provider value={contextValue}>
      {children}
      <OSMWalletKitModal open={showModal} onClose={contextValue.onHideModal} />
    </OsmosisWalletKitProviderContext.Provider>
  );
}
