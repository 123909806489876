import { Transaction } from "@mysten/sui/transactions";
import {
  useCurrentWallet,
  useDisconnectWallet,
  useSignAndExecuteTransaction,
} from "@mysten/dapp-kit";

export function useSuiWalletKit() {
  const { currentWallet } = useCurrentWallet();
  const { mutate: disconnect } = useDisconnectWallet();
  const { mutate: signAndExecuteTransaction } = useSignAndExecuteTransaction();

  const sendTx = async (input: Transaction): Promise<string> => {
    return new Promise((resolve, reject) => {
      signAndExecuteTransaction(
        {
          chain: "sui:mainnet",
          transaction: input,
        },
        {
          onSuccess: (result) => {
            resolve(result.digest);
          },
          onError: (error) => {
            reject(error);
          },
        },
      );
    });
  };

  return {
    address: currentWallet?.accounts[0]?.address ?? "",
    onDisconnect: disconnect,
    sendTx,
  };
}
