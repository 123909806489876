import { ReactNode } from "react";
import BTCWalletKitModal from "./BTCWalletKitModal";
import { LaserEyesProvider, MAINNET } from "@omnisat/lasereyes";

export function BTCWalletProvider({ children }: { children: ReactNode }) {
  return (
    <LaserEyesProvider config={{ network: MAINNET }}>
      {children}
      <BTCWalletKitModal />
    </LaserEyesProvider>
  );
}
